import React from "react";
import MUIDataTable from "mui-datatables";
import {makeSingleFilterDialog} from '../utils/ConfigTableColumns'
// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

// const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MUIDataTableBodyCell: {
//         root: {
//           padding: "3px 6px",
//         },
//       },
//       MUIDataTableSelectCell: {
//         checked: {color: "green !important" }
//       }
//     },
//   });

export default function TableMui(props) {
  return (
    // <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={props.title}
        data={props.data}
        columns={makeSingleFilterDialog(props.cols)}
        options={props.options}
      />
    // </MuiThemeProvider>
  );
}
