import React, { useState, useEffect } from 'react'
import Routes from './Routes'
import AppBar from './components/AppBar'
import './App.css'
import { withSnackbar } from 'notistack'
import { getUserDetails } from './utils/GraphService'
import { UserAgentApplication } from 'msal'
import config from './utils/Config'
import LambdaFetch from './functions/FetchFromLambda'
import { Typography, Button } from '@material-ui/core'
import MyLinearProgress from './utils/LinearProgress'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

const { REACT_APP_REDIRECT_URI, REACT_APP_CLIENT_ID } = process.env

const userAgentApplication = new UserAgentApplication({
  auth: {
    authority: config.authority,
    clientId: REACT_APP_CLIENT_ID,
    redirectUri:
      window.location.host === 'localhost:3000'
        ? 'http://localhost:3000'
        : REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateCookie: true
  }
})

const getUserProfile = async isLoggingIn => {
  try {
    const accessToken = await userAgentApplication.acquireTokenSilent({
      scopes: config.scopes
    })

    if (!accessToken.uniqueId) {
      return false
    }

    const token = await LambdaFetch(
      'login',
      'post',
      accessToken.accessToken,
      JSON.stringify({
        accessToken: accessToken,
        isLoggingIn
      }),
      '',
      accessToken
    )

    if (token) {
      const user = await getUserDetails(accessToken)

      const userRoles = accessToken.idTokenClaims
        ? accessToken.idTokenClaims.roles
        : null

      return {
        token,
        user,
        accessToken
      }
    }
  } catch (err) {
    console.log(err)
    this.login()
  }
}

function AppFuction (props) {
  const [state, setState] = useState({
    isAuth: false,
    isGettingProfile: true,
    isInvalidUser: false,
    user: {},
    appWidth: 1000,
    primaryAppColor: '#0F3D1A'
  })

  useEffect(() => {
    async function inital () {
      calcSize()
      var user = userAgentApplication.getAccount()

      if (user) {
        const userInfo = await getUserProfile()
        if (userInfo) {
          updateUser(userInfo)
        } else {
          login()
        }
      } else {
        login()
        setState({ ...state, isGettingProfile: false })
      }
      window.addEventListener('resize', calcSize.bind(this))
    }
    inital()
    return () => window.removeEventListener('resize', calcSize.bind(this))
  }, [])

  const calcSize = () => {
    setState({ ...state, appWidth: window.innerWidth })
  }

  const createSnack = (message, type, duration) => {
    props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      variant: type,
      autoHideDuration: duration
    })
  }
  const getUser = async () => {
    const userInfo = await getUserProfile()
    updateUser(userInfo)
  }

  const updateUser = userInfo => {
    setState({
      ...state,
      isAuth: true,
      user: {
        displayName: userInfo.user.displayName,
        email: userInfo.user.mail || userInfo.user.userPrincipalName,
        roles: userInfo.accessToken.idTokenClaims
          ? userInfo.accessToken.idTokenClaims.roles
          : null,
        roleActions: userInfo.token.data.roleActions,
        accessToken: userInfo.token.data.token
      },
      isGettingProfile: false
    })
  }

  const login = async () => {
    try {
      await userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account'
      })
      const userInfo = await getUserProfile()
      updateUser(userInfo)
    } catch (err) {
      return err
    }
  }
  const logout = async () => {
    await LambdaFetch(
      'audit',
      'post',
      state.user.accessToken,
      JSON.stringify({
        action: 'write',
        type: 'logout'
      }),
      '',
      state.user
    )

    userAgentApplication.logout()
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: state.primaryAppColor
      }
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: '3px 6px'
        }
      },
      MUIDataTableSelectCell: {
        checked: { color: `${state.primaryAppColor} !important` }
      }
    }
  })

  if (state.isGettingProfile) {
    return null
  }

  return (
    <div className='App'>
      <MuiThemeProvider theme={theme}>
        <div style={{ margin: '0 auto' }}>
          <AppBar
            appState={state}
            logout={logout}
            login={login}
            user={state.user}
          >
            {state.isGettingProfile ? (
              <MyLinearProgress />
            ) : (
              <div style={{ maxWidth: '1400px', margin: 'auto' }}>
                <div>
                  {state.isAuth ? (
                    <Routes
                      login={login}
                      createSnack={createSnack}
                      credentials={{
                        ...state,
                        login: login
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        height: '200px',
                        position: 'relative'
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      >
                        <h3>Welcome, please sign in</h3>
                        <div style={{ textAlign: 'center' }}>
                          {' '}
                          <Button
                            size='large'
                            color='primary'
                            onClick={() => login()}
                          >
                            Log In
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </AppBar>
        </div>
      </MuiThemeProvider>
    </div>
  )
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isAuth: false,
      isGettingProfile: true,
      isInvalidUser: false,
      user: {},
      appWidth: 1000,
      primaryAppColor: '#1482C5',
      trinaryAppColor: '#0d6eaa'
    }
    this.UserAgentApplication = new UserAgentApplication({
      auth: {
        authority: config.authority,
        clientId: REACT_APP_CLIENT_ID,
        redirectUri:
          window.location.host === 'localhost:3000'
            ? 'http://localhost:3000'
            : REACT_APP_REDIRECT_URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateCookie: true
      }
    })

    this.getMuiTheme = () =>
      createTheme({
        palette: {
          primary: { main: this.state.primaryAppColor }
        },
        overrides: {
          MUIDataTableBodyCell: {
            root: {
              padding: '3px 6px'
            }
          },
          MUIDataTableSelectCell: {
            checked: { color: `${this.state.primaryAppColor} !important` }
          }
        }
      })
  }

  componentDidMount = async () => {
    this.calcSize()

    const resp = await LambdaFetch(
      'login',
      'post',
      null,
      JSON.stringify({
        action: 'get-client-values'
      }),
      '',
      null
    )

    const colorScheme = resp.data.clientValues.reduce((acc, cur) => {
      if (cur.R_KEY === 'primary_color' && cur.R_ACTIVE === 1) {
        acc['primaryColor'] = cur.R_VALUE
      }
      if (cur.R_KEY === 'trinary_color' && cur.R_ACTIVE === 1) {
        acc['trinaryColor'] = cur.R_VALUE
      }
      return acc
    }, {})

    this.setAppColor(colorScheme.primaryColor, colorScheme.trinaryColor)

    var user = this.UserAgentApplication.getAccount()
    if (user) {
      this.getUserProfile(false)
    } else {
      this.setState({ isGettingProfile: false })
    }
    window.addEventListener('resize', this.calcSize.bind(this))
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.calcSize.bind(this))
  }
  calcSize = () => {
    this.setState({ appWidth: window.innerWidth })
  }
  createSnack = (message, type, duration) => {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      variant: type,
      autoHideDuration: duration
    })
  }
  setAppColor = (primaryColor, trinaryColor) => {
    const root = document.documentElement
    const primary = primaryColor ? primaryColor : '#1482C5'
    const trinary = trinaryColor ? trinaryColor : '#0d6eaa'
    root.style.setProperty('--primary-color', primary)
    root.style.setProperty('--trinary-color', trinary)
    this.setState({ primaryAppColor: primary, trinaryAppColor: trinary })
  }
  silentLogout = () => this.UserAgentApplication.logout()
  logout = async () => {
    await LambdaFetch(
      'audit',
      'post',
      this.state.user.accessToken,
      JSON.stringify({
        action: 'write',
        type: 'logout'
      }),
      '',
      this.state.user
    )

    this.UserAgentApplication.logout()
  }

  login = async () => {
    try {
      await this.UserAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account'
      })
      const gotUser = await this.getUserProfile(true)
      if (gotUser) {
        await LambdaFetch(
          'audit',
          'post',
          this.state.user.accessToken,
          JSON.stringify({
            action: 'write',
            type: 'login'
          }),
          '',
          this.state.user
        )
      }
    } catch (err) {
      this.setState({
        isAuth: false,
        user: {},
        error: { message: err }
      })
    }
  }
  getUserProfile = async isLoggingIn => {
    try {
      this.setState({ isGettingProfile: true })
      const accessToken = await this.UserAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      })

      if (!accessToken.uniqueId) {
        this.login()
        return null
      }

      const token = await LambdaFetch(
        'login',
        'post',
        accessToken.accessToken,
        JSON.stringify({
          accessToken: accessToken,
          isLoggingIn
        }),
        '',
        accessToken
      )

      if (token) {
        const user = await getUserDetails(accessToken)

        const userRoles = accessToken.idTokenClaims
          ? accessToken.idTokenClaims.roles
          : null

        if (!userRoles) {
          console.log(`Error: User no idToken Claims`)
          this.setState({
            isInvalidUser: true,
            isGettingProfile: false
          })
          return null
        } else {

          const pagePermission = token.data.roleActions.reduce(
            (acc, cur) => {
              if (cur.type === 'page') {
                if (acc[cur.filter1]) {
                  acc[cur.filter1] = acc[cur.filter1] ? true : cur.active
                } else {
                  acc[cur.filter1] = cur.active === 1 ? true : false
                }
              }
              return acc
            },
            {}
          )

          this.setState({
            isAuth: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              roles: accessToken.idTokenClaims
                ? accessToken.idTokenClaims.roles
                : null,
              // accessToken: accessToken.accessToken,
              roleActions: token.data.roleActions,
              accessToken: token.data.token,
              pagePermission: pagePermission
            },
            isGettingProfile: false
          })
          return true
        }
      }
    } catch (err) {
      console.log(err)
      this.login()
    }
  }

  render () {
    if (this.state.isGettingProfile) {
      return null
    }

    return (
      <div className='App'>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <div style={{ margin: '0 auto' }}>
            <AppBar
              appState={this.state}
              logout={this.logout}
              login={this.login}
              user={this.state.user}
            >
              {this.state.isInvalidUser ? (
                <div className='vertical-center text-center'>
                  <Typography variant='h5'>
                    It looks like you do not have access
                  </Typography>
                  <Typography variant='subtitle2'>
                    {' '}
                    Please contact your administrator
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => this.silentLogout()}
                  >
                    Logout
                  </Button>
                </div>
              ) : (
                <>
                  {this.state.isGettingProfile ? (
                    <MyLinearProgress />
                  ) : (
                    <div style={{ maxWidth: '1400px', margin: 'auto' }}>
                      <div>
                        {this.state.isAuth ? (
                          <Routes
                            login={this.login}
                            userRoles={this.userRoles}
                            createSnack={this.createSnack}
                            credentials={{
                              ...this.state,
                              login: this.login
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              height: '200px',
                              position: 'relative'
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                              }}
                            >
                              <h3>Welcome, please sign in</h3>
                              <div style={{ textAlign: 'center' }}>
                                {' '}
                                <Button
                                  size='large'
                                  color='primary'
                                  onClick={() => this.login()}
                                >
                                  Log In
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </AppBar>
          </div>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default withSnackbar(App)
