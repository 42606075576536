import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import { VendorInvoices } from '../components/VendorInvoices'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingButton from '../components/LoadingButton'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function InvoiceLookup (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    invoices: null,
    isLoading: false
  })

  const handleSubmit = async e => {
    e.preventDefault()
    getInvoices(e.target.lookup.value)
  }

  const getInvoices = async lookup => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'invoice-lookup',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'lookup',
          lookup
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        invoices: resp.data.invoices,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Invoice Lookup
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
                <TextField
                  id='lookup'
                  label='Lookup'
                  required
                  autoComplete='off'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'></InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {!!state.invoices && (
          <Card style={{ marginTop: '1rem' }}>
            <CardContent style={{ position: 'relative' }}>
              <VendorInvoices
                {...props}
                data={state.invoices}
                vendor={''}
                showVendor
                accessToken={credentials.user.accessToken}
                credentials={credentials}
                fileName={`Invoices`}
              />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  )
}
