import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Slide
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { Button } from '@material-ui/core'
import { currencyTextFormat } from '../utils/formatter'
import { AntTabs } from '../components/VendorTabs'
import SimpleTable from '../components/common/SimpleTable'
import {dateFormat} from '../utils/formatter';

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function ItemDetail (props) {
  const [tabVal, setTabVal] = useState(0)

  const classes = useStyles()
  const { credentials } = props.fetchInitialData

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  const { itemLocData } = props
  return (
    <>



          <div style={{ marginTop: '1rem' }}>
            <Grid
              container
              spacing={1}
              direction='row'
              justify='flex-start'
              alignItems='stretch'
            >
              <Grid item xs={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant='h6'>Main</Typography>

                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justify='space-evenly'
                    alignItems='flex-start'
                  >
                    <Grid item xs={12} sm={6}>
                      {printValue(itemLocData, 'GL_CATEGORY', 'GL Category')}
                      {printValue(
                        itemLocData,
                        'TRACKING_FL',
                        'Inventory Tracked'
                      )}
                      {printValue(itemLocData, 'PREFER_BIN', 'Preferred Bin')}
                      {printValue(itemLocData, 'STORAGE_CODE', 'Storage Code')}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {printValue(itemLocData, 'ADDED_DATE', 'Date Added')}
                      {printValue(itemLocData, 'ACTIVE_STATUS', 'Status')}
                      {printValue(itemLocData, 'CONSIGNMENT_FL', 'Consignment')}
                      {printValue(
                        itemLocData,
                        'CHARGEABLE_FL',
                        'Patient Chargeable'
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant='h6'>Reorder Information</Typography>

                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justify='space-between'
                    alignItems='flex-start'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      {printValue(
                        itemLocData,
                        'REORDER_PT_CD_DESC',
                        'Reorder Policy'
                      )}
                      {printValue(
                        itemLocData,
                        'REORDER_POINT',
                        'Reorder Point'
                      )}
                      {printValue(
                        itemLocData,
                        'REORD_QTY_CD_DESC',
                        'Reorder Code'
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      {printValue(itemLocData, 'UOM', 'UOM')}
                      {printValue(itemLocData, 'SOURCE_UOM', 'Source UOM')}
                      {printValue(itemLocData, 'REORDER_QTY', 'Reorder Qty')}
                      {printValue(itemLocData, 'ORDER_MULT', ' Order Multiple')}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      {printValue(itemLocData, 'MAX_ORDER', 'Maximum Order')}
                      {printValue(itemLocData, 'MIN_ORDER', 'Minimum Order')}
                      {printValue(itemLocData, 'SAFETY_STOCK', 'Safty Stock')}
                      {printValue(itemLocData, 'EOQ', 'Economic Order Qty')}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant='h6'>User Fields</Typography>

                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justify='space-between'
                    alignItems='flex-start'
                  >
                    <Grid item xs={12} sm={6} md={12}>
                      {printValue(itemLocData, 'USER_FIELD1', 'User Field 1')}
                      {printValue(itemLocData, 'USER_FIELD2', 'User Field 2')}
                      {printValue(itemLocData, 'USER_FIELD3', 'User Field 3')}
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      {printValue(
                        itemLocData,
                        'USER_FIELD_N1',
                        'User Field N1'
                      )}
                      {printValue(
                        itemLocData,
                        'USER_FIELD_N2',
                        'User Field N2'
                      )}
                      {printValue(
                        itemLocData,
                        'USER_FIELD_N3',
                        'User Field N3'
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant='h6'>Quantity Information</Typography>
                  {printValue(itemLocData, 'AVG_SOH_QTY', 'Average SOH Qty')}
                  {printValue(itemLocData, 'SOH_QTY', 'SOH Qty')}
                  {printValue(itemLocData, 'MOVE_CLASS', 'Move Class')}
                  {printValue(itemLocData, 'TURN_RATE', 'Turn Rate')}
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant='h6'>Cost Information</Typography>
                  {printValue(itemLocData, 'CHARGEABLE_FL', ' Charge')}
                  {printValue(itemLocData, 'AVERAGE_COST', 'Average Cost')}
                  {printValue(itemLocData, 'STD_COST', 'Standard Cost')}
                </Paper>
              </Grid>
            </Grid>
          </div>
        
    </>
  )
}
