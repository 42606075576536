import React from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import getTableOptions from '../constants/TableOptions';
import { benefitCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';
import ReactTable from '../components/ReactTable'

export default function Benefits(props) {
	if (!props.data) return null;
	return <ReactTable cols={benefitCols} data={props.data} />;
}
