import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Menu
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Link } from 'react-router-dom'
import './AppBar.css'
import { smallScreenWidth } from '../constants/AppConstants'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  leftMenu: {
    flexGrow: 1,
    display: 'flex'
  },
  menuInfo: {
    color: 'grey',
    outline: 0
  },
  '&:focus': {
    outline: 0
  }
}))

export default function ButtonAppBar (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [state, setState] = React.useState({
    closed: true
  })
  const open = Boolean(anchorEl)
  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  let showReports = false

  if (props.appState.isAuth) {
    showReports = props.appState.user.roleActions.reduce((acc, cur) => {
      if (cur.type === 'page' && cur.filter1 === 'reports' && cur.active === 1)
        acc = true
      return acc
    }, false)
  }

  return (
    <div className={classes.root}>
      {props.appState.isAuth && (
        <>
          {props.appState.appWidth < smallScreenWidth ? (
            <AppBar position='static' className='primeColor'>
              <Toolbar>
                <div className={classes.leftMenu}>
                  <img
                    alt='apix'
                    className='logo_name'
                    src='/apix-logo-white.png'
                    height={'85px'}
                  />
                </div>
                {props.appState.isAuth && (
                  <div
                    style={{
                      marginRight:
                        props.appState.appWidth < 700 ? '1rem' : '2rem',
                      display: 'flex'
                    }}
                  >
                    <Link className='appBarLink' to={'/'}>
                      <Typography
                        variant='overline'
                        className='appLBarLink'
                        style={{
                          marginRight:
                            props.appState.appWidth < 600 ? '1rem' : '1.5rem'
                        }}
                      >
                        Dashboard
                      </Typography>
                    </Link>
                    {showReports && (
                      <Link className='appBarLink' to={'/reports'}>
                        <Typography variant='overline'>Reports</Typography>
                      </Link>
                    )}
                  </div>
                )}
                {!!props.appState.isAuth ? (
                  <div>
                    <IconButton
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={handleMenu}
                      color='inherit'
                    >
                      <AccountCircle />
                    </IconButton>

                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorEl}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        className={classes.menuInfo}
                        style={{ padding: '4px 12px' }}
                      >
                        <div style={{ color: '#595959', marginBottom: '0' }}>
                          {props.user.displayName}
                        </div>
                        <div style={{ color: '#383838', fontSize: '0.85rem' }}>
                          {props.user.email}
                        </div>
                      </div>

                      <hr style={{ marginBottom: 0 }} />
                      <MenuItem onClick={() => props.logout()}>
                        Sign Out
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <Button
                    onClick={() =>
                      props.appState.isAuth ? props.logout() : props.login()
                    }
                    color='inherit'
                  >
                    {props.appState.isAuth ? 'Logout' : 'Log In'}
                  </Button>
                )}
              </Toolbar>
            </AppBar>
          ) : (
            <div className={`sidebar ${state.closed ? '' : 'open'}`}>
              <div className='logo-details'>
                <img
                  alt='apix'
                  className='logo_name'
                  src='/apix-logo-white.png'
                  height='85px'
                />

                <i
                  className='bx bx-menu'
                  onClick={() => setState({ closed: !state.closed })}
                  id='btn'
                ></i>
              </div>

              <ul className='nav-list'>
                <li>
                  <Link className='appBarLink' to={'/'}>
                    <i className='bx bx-grid-alt'></i>
                    <span className='links_name'>Dashboard</span>
                  </Link>
                  <span className='tooltip'>Dashboard</span>
                </li>
                {!!showReports ? (
                  <li>
                    <Link className='appBarLink' to={'/reports'}>
                      <i className='bx bxs-report'></i>
                      <span className='links_name'>Reports</span>
                    </Link>
                    <span className='tooltip'>Reports</span>
                  </li>
                ) : (
                  ''
                )}
                <li className='profile'>
                  <div className='profile-details'>
                    <div className='name_job'>
                      <div className='name'>{props.user.displayName}</div>
                      <div className='job'>{props.user.email}</div>
                    </div>
                  </div>
                  <i
                    className='bx bx-log-out signoutbutton'
                    id='log_out'
                    onClick={() => props.logout()}
                  ></i>
                </li>
              </ul>
            </div>
          )}
        </>
      )}
      <section
        className={`home-section${
          !props.appState.isAuth || props.appState.appWidth < smallScreenWidth
            ? '-fullWidth'
            : ''
        }`}
      >
        {props.children}
      </section>
    </div>
  )
}
