import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  Card,
  CardContent
} from '@material-ui/core'
import TableMui from '../components/TableMui'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { requisitionInquiryCols, requisitionInquiryReactTableCols } from '../constants/RequisitionCols'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import {dateFormat} from '../utils/formatter'
import ReactTable from '../components/ReactTable'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function RequisitionInquiry (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    selectedRequisition: '',
    selectedCompany: '',
    requisitionHeaderData: null,
    requisitionLineData: null,
    isLoading: false
  })
  const [companySelected, setCompanySelected] = React.useState('')
  const [requisitionSelected, setrequisitionSelected] = React.useState('')
  const [showHeaders, setShowHeaders] = React.useState(true)

  const handleSubmit = e => {
    e.preventDefault()
    if (!companySelected || !requisitionSelected) return null
    getRequisitionData(companySelected, requisitionSelected)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const company = urlParams.get('company')
    const req = urlParams.get('req')
    if(company && req) {
      getRequisitionData(company, req)
      setShowHeaders(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getRequisitionData = async (comp, req) => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'requisition-inquiry',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-requisition',
          company: comp,
          req: req
        }),
        '',
        credentials
      )

      const reqLineData = resp.data.requisitionLineData.map(row => {
        return {...row, PO_LINK: (row.PO_NUMBER && row.PO_COMPANY) ? (
          <div className="editLink" onClick={() => {
            const win = window.open(`/po?company=${row.PO_COMPANY}&po=${row.PO_NUMBER}&pocode=${row.PO_CODE}`, "_blank");
            win.focus();
          }}>{row.PO_NUMBER}</div>
        ) : (
          ''
        )}
      })
      setstate({
        ...state,
        requisitionHeaderData: resp.data.requisitionHeaderData,
        requisitionLineData: reqLineData,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  const options = getTableOptions({
    cols: requisitionInquiryCols,
    fileName: `Requisition_Inquiry_${companySelected}_${requisitionSelected}`
  })

  const { requisitionHeaderData, requisitionLineData } = state
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Requisition Inquiry (RQ44)
      </Typography>

      <div className={classes.root}>
      {showHeaders && (
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={false}
                  required={true}
                  id='company'
                  width='150px'
                  label='Company'
                  accessToken={credentials.user.accessToken}
                  error='No Company Found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    setCompanySelected(id)
                  }}
                  credentials={credentials}
                  apiResource='requisition-inquiry'
                  secondaryParams={{
                    limit: 10,
                    action: 'autocomplete_company'
                  }}
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  id='requisition'
                  required={true}
                  width='150px'
                  label='Requisition'
                  accessToken={credentials.user.accessToken}
                  error={'No Requisition Found'}
                  variant='standard'
                  handleChange={(id, hit) => {
                    setrequisitionSelected(id)
                  }}
                  credentials={credentials}
                  apiResource='requisition-inquiry'
                  secondaryParams={{
                    limit: 10,
                    company: companySelected,
                    action: 'autocomplete_requisition'
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>
      )}
        {!!requisitionHeaderData && (
        <div style={{ marginTop: '1rem' }}>
          <Card style={{ margin: '1rem auto' }}>
            <CardContent style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  margin: '0.5rem 0',
                  justifyContent: 'flex-start'
                }}
              >
                <div>
            
                  <Typography variant='h5'>{`Company: ${requisitionHeaderData.COMPANY}`}</Typography>
                  <Typography variant='subtitle1'>{`Requisition Number: ${requisitionHeaderData.REQ_NUMBER}`}</Typography>
                </div>
              </div>
           
                <Grid
                  container
                  spacing={1}
                  direction='row'
                  justify='flex-start'
                  alignItems='stretch'
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant='h5'>Main</Typography>
                      <Grid
                        container
                        spacing={0}
                        direction='row'
                        justify='space-evenly'
                        alignItems='flex-start'
                      >
                        <Grid item xs={12} sm={6}>
                          {printValue(
                            requisitionHeaderData,
                            'REQUESTER',
                            'Requester',
                            'REQUESTER_NAME'
                          )}
                          {printValue(requisitionHeaderData, 'REQUESTER_NAME', 'Name')}
                          {printValue(
                            requisitionHeaderData,
                            'REQ_LOCATION',
                            'Location',
                            'LOCATION_NAME'
                          )}
                          {printValue(
                            requisitionHeaderData,
                            'DELIVER_TO',
                            'Deliver To'
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {printValue(
                            requisitionHeaderData,
                            'BUYER_CODE',
                            'Buyer',
                            'BUYER_NAME'
                          )}
                          {printValue(
                            requisitionHeaderData,
                            'REQ_DEL_DATE',
                            'Date'
                          )}
                          {printValue(
                            requisitionHeaderData,
                            'XSTATUS',
                            'Status'
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
      
                <Grid
                  item
                  xs={12}
                  style={{ position: 'relative', marginTop: '1rem' }}
                >
                  <ReactTable cols={requisitionInquiryReactTableCols} data={requisitionLineData} />
                </Grid>
         
            </CardContent>
          </Card>
        </div>
             )}
      </div>
    </div>
  )
}
