import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  Card,
  CardContent
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import LoadingCircle from '../components/common/LoadingCircle'
import LoadingButton from '../components/LoadingButton'
import ItemLocDetail from '../components/ItemLocDetail'
import ItemTransDetail from '../components/ItemTransDetail'
import { smallScreenWidth } from '../constants/AppConstants'
import { AntTabs } from '../components/VendorTabs'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2';

import PageDialog from '../components/PageDialog';
import ItemDetail from '../components/ItemDetail'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function ItemLocation (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    itemData: null,
    itemTransData: null,
    companies: [],
    fetched: false,
    isFetching: false
  })
  const [headers, setHeaders] = React.useState({
    company: null,
    location: null,
    item: null
  })
  const [tabVal, setTabVal] = useState(0)
  const [itemData, setItemData] = useState({open: false, data: null})
  useEffect(() => {
    async function fetch () {
      try {
        const resp = await LambdaFetch(
          'item',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'get-companies'
          }),
          '',
          credentials
        )
        setstate({ ...state, companies: resp.data.companies, fetched: true })
      } catch (e) {
        console.log(e)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getItemLoc = async e => {
    e.preventDefault()
    try {
      setstate({ ...state, isFetching: true })

      const resp = await LambdaFetch(
        'item',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-itemLoc',
          company: headers.company,
          location: headers.location,
          item: headers.item
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        itemLocData: resp.data.itemLoc[0],
        itemTransData: resp.data.icTrans,

        isFetching: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getItem = async (itemGroup, item) => {
    try {
      const resp = await LambdaFetch(
        'item',
        'post',
        credentials.user.accessToken,
        JSON.stringify({  
          action: 'get-item',
          itemGroup,
          item
        }),
        '',
        credentials
      )
      setItemData({
        ...itemData,
        open: true,
        data: resp.data.item[0]
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: value })
  }

  const { itemLocData, itemTransData } = state
  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <PageDialog
      {...props}
      handleClose={() => setItemData({...itemData, open: false, data: null})}
      open={itemData.open}
      data={itemData.data}
      >
      <ItemDetail 
      {...props} itemData={itemData.data} 
      />
      </PageDialog>

      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        {'Item Location (IC12/IC50)'}
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={getItemLoc}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
                <SimpleAutoComplete
                  id={'company'}
                  name={'company'}
                  width='150px'
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  onChange={val => handleChange('company', val)}
                  options={state.companies.map(el => el['COMPANY'])}
                  getOptionLabel={val => val.toString()}
                />
              </Grid>

              <Grid item>

              <ServerSideAutoCompletev2
                  disabled={false}
                  id='location'
                  required={true}
                  width='320px'
                  label='Location'
                  accessToken={credentials.user.accessToken}
                  error='No Location found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    handleChange('location', id)
                  }}
                  credentials={credentials}
                  apiResource='item'
                  secondaryParams={{
                    limit: 25,
                    company: headers.company,
                    action: 'autocomplete-item-location'
                  }}
                />
              </Grid>

              <Grid item>
              <ServerSideAutoCompletev2
                  disabled={false}
                  id='item'
                  width='320px'
                  label='Item'
                  required={true}
                  accessToken={credentials.user.accessToken}
                  error='No Item found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    handleChange('item', id)
                  }}
                  credentials={credentials}
                  apiResource='item'
                  secondaryParams={{
                    limit: 25,
                    company: headers.company,
                    location: headers.location,
                    action: 'autocomplete-item-location-item'
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isFetching}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {(itemLocData || itemTransData) && (
          <Card
            style={{ marginTop: '1rem' }}
            elevation={props.inverseElevation ? 0 : 2}
          >
            <CardContent style={{ position: 'relative' }}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '0.5rem',
                  justifyContent: 'flex-start'
                }}
              >
                <div>
                  <Typography variant='h5'>
                    <span>Item: </span>
                     <span className="editLink" onClick={() => getItem(itemLocData.ITEM_GROUP, itemLocData.ITEM)}>{`${itemLocData.DESCRIPTION} (${itemLocData.ITEM.trim()})`}</span>
                 </Typography>
                  <Typography variant='subtitle1'>{`COMPANY: ${itemLocData.COMPANY}`}</Typography>
                  <Typography variant='subtitle2'>{`Location: ${itemLocData.LOCATION.trim()}`}</Typography>
                </div>
              </div>

              <AntTabs
                value={tabVal}
                color={credentials.primaryAppColor}
                handleChange={(event, newValue) => {
                  setTabVal(newValue)
                }}
                tabs={['Location', 'Transactions']}
              />
              <div style={{ marginTop: '1rem' }}>
                {tabVal === 0 && itemLocData && (
                  <Grid
                    item
                    xs={12}
                    style={{ position: 'relative', marginTop: '1rem' }}
                  >
                    <ItemLocDetail {...props} itemLocData={itemLocData} />
                  </Grid>
                )}
                {tabVal === 1 && itemTransData && (
                  <Grid
                    item
                    xs={12}
                    style={{ position: 'relative', marginTop: '1rem' }}
                  >
                    <ItemTransDetail {...props} itemTransData={itemTransData}
                    fileName={`Item_Trans_${itemLocData.DESCRIPTION}_${itemLocData.LOCATION.trim()}`} />
                  </Grid>
                )}
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  )
}
