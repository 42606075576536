import { dateOptions } from '../utils/CustomTableDateFilter'
import { dateFormat } from '../utils/formatter'

export const staticFileCols = [
  {
    name: 'file_name',
    label: 'File',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'file_description',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'size',
    label: 'Size',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'uploaded_by',
    label: 'Uploaded By',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'upload_date',
    label: 'Upload Date',
    options: {
      ...dateOptions({ displayName: 'Date' }),
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'link',
    label: 'Download',
    options: {
      filter: false,
      sort: false
    }
  }
]
