import React, { useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import LambdaFetch from '../functions/FetchFromLambda'
import { debounce } from 'throttle-debounce'
import { Box, IconButton } from '@mui/material'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { v4 as uuidv4 } from 'uuid'
import CustomFooter from '../utils/CustomDataTableFooter'

const ReactTableServerSide = props => {
  //data and fetching state
  const [data, setData] = useState([])
  const [initalRender, setInitalRender] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRefetching, setIsRefetching] = useState(false)
  const [rowCount, setRowCount] = useState(0)

  //table state
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  })

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true
  }

  const csvExporter = new ExportToCsv(csvOptions)

  useEffect(() => {
    if(initalRender) return 
    debounceFetchTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, pagination.pageSize, pagination.pageIndex, sorting])

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }
    try {
      const filters = columnFilters.reduce((acc, cur, index) => {
        acc[cur['id']] = cur.value ? cur.value : null
        return acc
      }, {})
      const resp = await LambdaFetch(
        props.apiResource,
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: props.action,
          params: props.params,
          columns: props.tableColumns,
          page: pagination.pageIndex,
          //sortOrder: sort,
          entriesPerPage: pagination.pageSize,
          filters
        }),
        '',
        props.fetchInitialData.credentials
      )
      console.log(resp)
      let tableData = props.transform
        ? props.transform(resp.data.tableData)
        : resp.data.tableData

      setData(tableData)
      setInitalRender(false)
      if (props.callback) {
        props.callback()
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
    setIsError(false)
    setIsLoading(false)
    setIsRefetching(false)
  }

  const debounceFetchTableData = debounce(1000, false, () => {
    fetchData()
  })

  const handleExportData = () => {
      DownloadData()
  }

  const DownloadData = async () => {
    try {
      const filters = columnFilters.reduce((acc, cur, index) => {
        acc[cur['id']] = cur.value ? cur.value : null
        return acc
      }, {})

      LambdaFetch(
        props.apiResource,
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: props.downloadAction,
          params: props.params,
          filters,
          docLink: uuidv4()
        }),
        '',
        props.fetchInitialData.credentials,
        true
      )
      props.fetchInitialData.createSnack(
        'File sent to print manager',
        'success',
        3000
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <MaterialReactTable
      columns={props.tableColumns}
      data={data}
      initialState={{...props.initialState, showColumnFilters: true, density: 'compact' }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data'
            }
          : undefined
      }
      enableGlobalFilter={false}
      enableStickyHeader
      enableFullScreenToggle={false}
      showSkeletons={true}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      muiTableBodyRowProps={({ row, table }) => {
        if (row.index % 2 === 0) {
          return { style: { background: '#F7F7F7' } }
        }
      }}
      count={(pagination.pageSize * pagination.pageIndex) + data.length }
      // rowCount={Math.min(data.length, pagination.pageSize)}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton
            onClick={handleExportData}
            icon={<CloudDownloadIcon />}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
      renderBottomToolbar={({ table }) => {
        return (
          <CustomFooter
            {...props}
            count={(pagination.pageSize * pagination.pageIndex) + data.length }
            page={pagination.pageIndex}
            rowsPerPage={pagination.pageSize}
            changeRowsPerPage={num =>
              setPagination({ ...pagination, pageSize: num })
            }
            changePage={num => setPagination({ ...pagination, pageIndex: num })}
            textLabels={'of'}
            tableData={data}
          />
        )
      }}
    />
  )
}

export default ReactTableServerSide
