const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantId = process.env.REACT_APP_TENANT_ID;

module.exports = {
    appId: clientId, 
    authority: `https://login.microsoftonline.com/${tenantId}/`, 
    scopes: [
        // "2406c1a9-b378-4968-ac94-8f9b2816f6d3/Profile.Read",
       "user.read"
    ]
}


