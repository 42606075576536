import React from 'react'
import { withStyles, Badge, Tabs, Tab } from '@material-ui/core'

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: props => props.color
  }
})(props => <Tabs {...props}/>)

export const AntTabsVert = withStyles({
  root: {
    maxWidth: '120px',
    margin: '0.5rem 0'
  },
  indicator: {
    backgroundColor: props => props.color
  }
})(props => <Tabs {...props}/>)

export const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: props => props.color,
      opacity: 1
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      outline: 'none'
    }
  },
  selected: {
    color: props => props.color
  }
}))(props => <Tab disableRipple {...props} />)

export const MyBadge = withStyles(theme => ({
  badge: {
    zIndex: 999,
    backgroundColor: props => props.bgcolor,
    color: 'white'
  }
}))(props => <Badge {...props} />)
