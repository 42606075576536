import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

   const useStyles = makeStyles({
  root: {
    height: "4px",
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: "#1E7E34"
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#f6ce95"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#1E7E34",
      filter: "brightness(.6)"
    },
    "& .MuiLinearProgress-dashedColorPrimary": {
      backgroundImage:
        "radial-gradient(#1E7E34 0%, #f6ce95 16%, transparent 42%)"
    }
  }
});

function MyLinearProgress(props) {
   const classes = useStyles();

return (
<LinearProgress
    color="primary"
    valueBuffer={40}
    value={20}
    classes={{
      root: classes.root
    }}
/>
)
}


export default (MyLinearProgress);