import React from 'react'
import { Card, CardContent, Typography, Grid } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import SubjectIcon from '@material-ui/icons/Subject'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PlaceIcon from '@material-ui/icons/Place'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import AssessmentIcon from '@material-ui/icons/Assessment'
import PieChartIcon from '@material-ui/icons/PieChart'
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PageviewIcon from '@mui/icons-material/Pageview';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ReceiptIcon from '@mui/icons-material/Receipt';

const iconFontSize = '30px'

const pages = [
  {
    title: 'HR',
    permission: 'HR',
    tiles: [
      {
        title: 'Employee',
        icon: <GroupIcon style={{ fontSize: iconFontSize }} />,
        link: 'employee'
      },
      {
        title: 'Payroll Distributions',
        icon: <CurrencyExchangeIcon style={{ fontSize: iconFontSize }} />,
        link: 'payroll-distrib'
      }
    ]
  },
  {
    title: 'AP',
    permission: 'AP',
    tiles: [
      {
        title: 'Vendor-Invoice-Payments',
        icon: <FolderSharedIcon style={{ fontSize: iconFontSize }} />,
        link: 'vendor'
      },
      {
        title: 'Invoice Lookup',
        icon: <FindInPageIcon style={{ fontSize: iconFontSize }} />,
        link: 'invoice-lookup'
      }
    ]
  },
  {
    title: 'AR',
    permission: 'AR',
    tiles: [
      {
        title: 'Customer Acitivty',
        icon: <PermContactCalendarIcon style={{ fontSize: iconFontSize }} />,
        link: 'customer-activity'
      }
    ]
  },
  {
    title: 'GL',
    permission: 'GL',
    tiles: [
      {
        title: 'Transactions',
        icon: <SubjectIcon style={{ fontSize: iconFontSize }} />,
        link: 'gl-trans'
      },
      {
        title: 'Accounts',
        icon: <AccountCircleIcon style={{ fontSize: iconFontSize }} />,
        link: 'gl-accounts'
      },
      {
        title: 'Budget',
        icon: <AccountBalanceWalletIcon style={{ fontSize: iconFontSize }} />,
        link: 'budget'
      },
      {
        title: 'Balances',
        icon: <AccountBalanceIcon style={{ fontSize: iconFontSize }} />,
        link: 'balances'
      },
      {
        title: 'Trial Balances',
        icon: <PriceCheckIcon style={{ fontSize: iconFontSize }} />,
        link: 'trial-balances'
      }
    ]
  },
  {
    title: 'Asset Management',
    permission: 'ASSET',
    tiles: [
      {
        title: 'Asset (AM20)',
        icon: <LocalAtmIcon style={{ fontSize: iconFontSize }} />,
        link: 'assets'
      },
      {
        title: 'Asset Analysis (AM70.1)',
        icon: <PieChartIcon style={{ fontSize: iconFontSize }} />,
        link: 'asset-analysis'
      },
      {
        title: 'Transaction Analysis (AM80.1)',
        icon: <AssessmentIcon style={{ fontSize: iconFontSize }} />,
        link: 'transaction-analysis'
      }
    ]
  },
  {
    title: 'Procurement',
    permission: 'PROCUREMENT',
    tiles: [
      {
        title: 'PO',
        icon: <ShoppingCartIcon style={{ fontSize: iconFontSize }} />,
        link: 'po'
      },
      {
        title: 'PO Vendor Invoice Payment',
        icon: <ShoppingCartIcon style={{ fontSize: iconFontSize }} />,
        link: 'po-vendor'
      },
      {
        title: 'PO Lookup',
        icon: <PageviewIcon style={{ fontSize: iconFontSize }} />,
        link: 'po-lookup'
      },
      {
        title: 'Item (IC11)',
        icon: <LocalOfferIcon style={{ fontSize: iconFontSize }} />,
        link: 'item'
      },
      {
        title: 'Item Location (IC12/IC50)',
        icon: <PlaceIcon style={{ fontSize: iconFontSize }} />,
        link: 'item-loc'
      },
      {
        title: 'Requisition Inquiry (RQ44)',
        icon: <PlaylistAddCheckIcon style={{ fontSize: iconFontSize }} />,
        link: 'requisition-inquiry'
      }
    ]
  },
  {
    title: 'Cash Book',
    permission: 'CASH',
    tiles: [
      {
        title: 'Cash Book Trans (CB80)',
        icon: <MonetizationOnIcon style={{ fontSize: iconFontSize }} />,
        link: 'cash-book-trans'
      }
    ]
  },
  {
    title: 'Activity',
    permission: 'ACTIVITY',
    tiles: [
      {
        title: 'AC Trans',
        icon: <ReceiptLongIcon style={{ fontSize: iconFontSize }} />,
        link: 'actrans'
      },
      {
        title: 'Bill of Cost (AC471)',
        icon: <ReceiptIcon style={{ fontSize: iconFontSize }} />,
        link: 'bill-cost'
      }
    ]
  }
]

export default function Home (props) {
  const pagePermission = props.fetchInitialData.credentials.user.roleActions.reduce(
    (acc, cur) => {
      if (cur.type === 'page') {
        if (acc[cur.filter1]) {
          acc[cur.filter1] = acc[cur.filter1] ? true : cur.active
        } else {
          acc[cur.filter1] = cur.active === 1 ? true : false
        }
      }
      return acc
    },
    {}
  )
  props.fetchInitialData.credentials.user["pagePermission"] = pagePermission
  return (
    <div style={{ padding: '1rem 1rem' }}>
      <div>
        <Grid container spacing={2}>
          {pages.map((p, index) => {
            return (
              <CardGroup
                key={index}
                pagePermission={pagePermission}
                history={props.history}
                title={p.title}
                tiles={p.tiles}
              />
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

function FormCard (props) {
  return (
    <Card
      className='homeTile'
      onClick={() => props.history.push(`/${props.link}`)}
    >
      <CardContent
        className='absoluteCenter'
        style={{ textAlign: 'center', padding: '4px', width: '100%' }}
      >
        {props.icon}
        <Typography color='textSecondary' style={{ fontSize: '13px' }}>
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  )
}

function CardGroup (props) {
  const groupTiles = props.tiles.filter(p => props.pagePermission[p.link])
  if (groupTiles.length === 0) return null
  return (
    <Grid item xs={12} sm={10} md={6} style={{width:"100%"}}>
      <div style={{ margin: '0.25rem' }}>
        <Typography
          variant='overline'
          style={{ fontSize: '1.15rem', color: '#545454' }}
          gutterBottom
        >
          {props.title}
        </Typography>

        <Grid
          container
          direction='row'
          justifycontent='flex-start'
          alignitems='stretch'
          spacing={1}
        >
          {groupTiles.map(el => {
            return (
              <Grid item key={el.title}>
                <FormCard
                  icon={el.icon}
                  title={el.title}
                  link={el.link}
                  history={props.history}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Grid>
  )
}
