import React from 'react'
import TableMui from './TableMui'
import moment from 'moment-timezone'
import LambdaFetch from '../functions/FetchFromLambda'
import DialogTable from './DialogTable'
import getTableOptions from '../constants/TableOptions'
import { perActHistCols, perActHistModalCols } from '../constants/EmployeeCols'
import { dateFormat } from '../utils/formatter'
import ReactTable from '../components/ReactTable'

export default function PersonalActHisotry (props) {
  const [objInfo, setObjInfo] = React.useState(null)
  const handleClose = () => {
    setObjInfo(null)
  }
  const getPerAction = async objId => {
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'per_act',
        objId,
        empId: props.empId,
        company: props.company
      }),
      '',
      props.fetchInitialData.credentials
    )

    console.log(resp)
    if (resp.success) {
      setObjInfo(
        resp.data.personalActDetail
        )
    } else {
      setObjInfo(null)
    }
  }
  if (!props.data) return null
  const options = getTableOptions({
    cols: perActHistCols,
    fileName: props.fileName
  })

  const tableData = props.data.map(row => {
    return {
      ...row,
      VIEW: <div className='editLink' onClick={() => getPerAction(row.OBJ_ID)}>
      view
    </div>
    }
  })
  return (
    <div>
      <DialogTable
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={perActHistModalCols}
      />
      <ReactTable cols={perActHistCols} data={tableData} />

      {/* <TableMui
        cols={perActHistCols}
        data={tableData}
        options={options}
        title=""
      /> */}
    </div>
  )
}
