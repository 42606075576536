import React from 'react'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MuiTablePagination from '@material-ui/core/TablePagination'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const defaultFooterStyles = {}

class CustomFooter extends React.Component {
  handleRowChange = event => {
    this.props.changeRowsPerPage(event.target.value)
  }

  handlePageChange = page => {
    this.props.changePage(page - 1)
  }

  render () {
    const { count, textLabels, rowsPerPage, page } = this.props
    const footerStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0px 24px 0px 24px'
    }
    return (
      <MuiTablePagination
        component='div'
        count={count}
        rowsPerPage={parseInt(rowsPerPage)}
        page={page}
        labelRowsPerPage={textLabels.rowsPerPage}
        labelDisplayedRows={({ from, to, count }) => {
          if (this.props.noPagination) {
            const showOfMany =
              this.props.tableData.length < rowsPerPage ? '' : 'of many'
            return `${page * rowsPerPage + 1}-${page * rowsPerPage +
              this.props.tableData.length} ${showOfMany}`
          }
          return `${from}-${to} ${textLabels.displayRows} ${count}`
        }}
        backIconButtonProps={{
          'aria-label': textLabels.previous
        }}
        nextIconButtonProps={{
          'aria-label': textLabels.next
        }}
        onPageChange={this.handlePageChange}
        onRowsPerPageChange={this.handleRowChange}
        ActionsComponent={
          this.props.noPagination
            ? props => (
                <TablePaginationActionsNoPagnination
                  {...props}
                  count={-1}
                  tableData={this.props.tableData}
                />
              )
            : TablePaginationActions
        }
      />
    )
  }
}

const TablePaginationActions = props => {
  const classes = useStyles1()
  const { count, page, rowsPerPage, onPageChange } = props

  let pages = [-1, 0, 1, 2, 3]
  let paginationPages = pages
    .map(page => {
      return props.page + page
    })
    .filter(page => page >= 1 && page < count / rowsPerPage + 1)

  return (
    <div className={classes.root}>
      {page > 4 && (
        <>
          <IconButton
            style={{ borderRadius: '5px' }}
            onClick={() => onPageChange(1)}
            disabled={page === 1}
            aria-label='first page'
          >
            <span style={{ fontSize: '14px' }}>{1}</span>
          </IconButton>
          <span style={{ fontSize: '14px', margin: '0.25rem' }}>...</span>
        </>
      )}

      {paginationPages.map(p => {
        return (
          <IconButton
            key={p}
            style={{ borderRadius: '5px' }}
            disabled={p - 1 === page}
            size='small'
            onClick={() => onPageChange(p)}
            aria-label='previous page'
          >
            <span style={{ margin: '0.25rem', fontSize: '14px' }}>{p}</span>
          </IconButton>
        )
      })}
      {page < Math.ceil(count / rowsPerPage) - 3 && (
        <>
          <span style={{ fontSize: '14px', margin: '0.25rem' }}>...</span>

          <IconButton
            style={{ borderRadius: '5px' }}
            onClick={() => onPageChange(Math.ceil(count / rowsPerPage))}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
          >
            <span style={{ fontSize: '14px' }}>
              {Math.ceil(count / rowsPerPage)}
            </span>
          </IconButton>
        </>
      )}
    </div>
  )
}

const TablePaginationActionsNoPagnination = props => {
  const classes = useStyles1()
  const { count, page, rowsPerPage, onPageChange, tableData } = props
  return (
    <div className={classes.root}>
      <IconButton
        style={{ borderRadius: '5px' }}
        onClick={() => onPageChange(1)}
        disabled={page === 0}
        aria-label='first page'
      >
        <KeyboardArrowLeftIcon />
      </IconButton>

      <IconButton
        style={{ borderRadius: '5px' }}
        onClick={() => onPageChange(page + 2)}
        disabled={tableData.length < rowsPerPage}
        aria-label='last page'
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  )
}

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(3.5)
  }
}))

export default withStyles(defaultFooterStyles, { name: 'CustomFooter' })(
  CustomFooter
)
