import {
  currencyFormat,
  noWrapFormat,
  dateFormat
} from '../utils/formatter'

export const poDetailCols = [
  {
    name: 'LINE_NBR',
    label: '#',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_TYPE',
    label: 'Type',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCH_DTL_KEY',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_BUY_UOM',
    label: 'UOM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_UNIT_CST',
    label: 'Unit Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'EARLY_DL_DATE',
    label: 'Delivery Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'REC_QTY',
    label: 'Rec Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCHED_QTY',
    label: 'Match Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOCATION',
    label: 'Loc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CLOSED_FL',
    label: 'Closed',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'EXTENDED_AMT',
    label: 'Ext Amt',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'VEN_ITEM',
    label: 'Ven Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE',
    label: 'Source',
    options: {
      filter: true,
      sort: true
    }
  },
]

export const poDetailReactTableCols = [
  {
    accessorKey: 'LINE_NBR',
    header: '#',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ITEM_TYPE',
    header: 'Type',
    filterFn: 'contains'
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Item',
    filterFn: 'contains'
  },
  {
    accessorKey: 'MATCH_DTL_KEY',
    header: 'Description',
    filterFn: 'contains'
  },
  {
    accessorKey: 'QUANTITY',
    header: 'Qty',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ENT_BUY_UOM',
    header: 'UOM',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ENT_UNIT_CST',
    header: 'Unit Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'EARLY_DL_DATE',
    header: 'Delivery Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'REC_QTY',
    header: 'Rec Qty',
    filterFn: 'contains'
  },
  {
    accessorKey: 'MATCHED_QTY',
    header: 'Match Qty',
    filterFn: 'contains'
  },
  {
    accessorKey: 'LOCATION',
    header: 'Loc',
    filterFn: 'contains'
  },
  {
    accessorKey: 'CLOSED_FL',
    header: 'Closed',
    filterFn: 'contains'
  },
  {
    accessorKey: 'EXTENDED_AMT',
    header: 'Ext Amt',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'VEN_ITEM',
    header: 'Ven Item',
    filterFn: 'contains'
  },
  {
    accessorKey: 'SOURCE',
    header: 'Source',
    filterFn: 'contains'
  },
]

export const poSourceLineCols = [
  {
    name: 'ORIGIN_CD',
    label: 'Origin',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DOCUMENT',
    label: 'Document',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SRC_LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REQUESTER',
    label: 'Requester',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REQ_LOCATION',
    label: 'Location',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_BUY_UOM',
    label: 'OUM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'NAME',
    label: 'Name',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'EMAIL_ADDRESS',
    label: 'Email',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  }
]


export const poReceiveCols = [
  {
    name: 'REC_LINE_VIEW',
    label: 'Receiver Num',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'OPER_ID',
    label: 'Operator',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BUYER',
    label: 'Buyer',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REC_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOGGED_FLAG',
    label: 'Logged',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REPRINT_REC_FL',
    label: 'Reprint',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTUAL_TIME',
    label: 'Time',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ERS_STATUS',
    label: 'Ers Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PRINT_CODE',
    label: 'Print Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCH_AMT',
    label: 'Match Amt',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCH_AMT_TOT',
    label: 'Total',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
]

export const poReceiveReactTableCols = [
  {
    accessorKey: 'REC_LINE_VIEW',
    header: 'Receiver Num',
    filterFn: 'contains'
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains'
  },
  {
    accessorKey: 'OPER_ID',
    header: 'Operator',
    filterFn: 'contains'
  },
  {
    accessorKey: 'BUYER',
    header: 'Buyer',
    filterFn: 'contains'
  },
  {
    accessorKey: 'REC_DATE',
    header: 'Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  },
  {
    accessorKey: 'STATUS',
    header: 'Status',
    filterFn: 'contains'
  },
  {
    accessorKey: 'LOGGED_FLAG',
    header: 'Logged',
    filterFn: 'contains'
  },
  {
    accessorKey: 'REPRINT_REC_FL',
    header: 'Reprint',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ACTUAL_TIME',
    header: 'Time',
    filterFn: 'contains'
  },
  {
    accessorKey: 'ERS_STATUS',
    header: 'Ers Status',
    filterFn: 'contains'
  },
  {
    accessorKey: 'PRINT_CODE',
    header: 'Print Code',
    filterFn: 'contains'
  },
  {
    accessorKey: 'MATCH_AMT',
    header: 'Match Amt',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'MATCH_AMT_TOT',
    header: 'Total',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
]

export const poRecLineCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  },
	{
    name: 'LINE_NBR',
    label: 	'Line',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM',
    label: 'Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'VEN_ITEM',
    label: 'Ven Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOCATION',
    label: 'Location',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_REC_QTY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_REC_UOM',
    label: 'UOM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_RUOM_MULT',
    label: 'Ent RUOM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REJECT_QTY',
    label: 'Rejected Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ORIG_REC_QTY',
    label:  'orig Rec Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CATCH_WT_QTY',
    label: 'Catch Wt Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ORIG_UNIT_CST',
    label:  'Orig Unit Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCH_UNIT_CST',
    label: 'Match Unit Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BIN',
    label: 'Bin',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CANCEL_BO_FL',
    label: 'Cancel Bo Fl',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INSP_REQ_FL',
    label: 'Insp Req Fl',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DETAIL_BAL_FL',
    label: 'Detail Bal Fl',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REC_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  }
]

export const poRecLineReactTableCols = [
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains',
  },
	{
    accessorKey: 'LINE_NBR',
    header: 	'Line',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ITEM',
    header: 'Item',
    filterFn: 'contains',
  },
  {
    accessorKey: 'DESCRIPTION',
    header: 'Description',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ITEM_TYPE',
    header: 'Type',
    filterFn: 'contains',
  },
  {
    accessorKey: 'VEN_ITEM',
    header: 'Ven Item',
    filterFn: 'contains',
  },
  {
    accessorKey: 'LOCATION',
    header: 'Location',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ENT_REC_QTY',
    header: 'Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ENT_REC_UOM',
    header: 'UOM',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ENT_RUOM_MULT',
    header: 'Ent RUOM',
    filterFn: 'contains',
  },
  {
    accessorKey: 'REJECT_QTY',
    header: 'Rejected Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ORIG_REC_QTY',
    header:  'orig Rec Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'CATCH_WT_QTY',
    header: 'Catch Wt Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ORIG_UNIT_CST',
    header:  'Orig Unit Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'MATCH_UNIT_CST',
    header: 'Match Unit Cost',
    filterFn: 'contains',
    Cell: ({ cell }) => currencyFormat(cell.getValue())
  },
  {
    accessorKey: 'BIN',
    header: 'Bin',
    filterFn: 'contains',
  },
  {
    accessorKey: 'CANCEL_BO_FL',
    header: 'Cancel Bo Fl',
    filterFn: 'contains',
  },
  {
    accessorKey: 'INSP_REQ_FL',
    header: 'Insp Req Fl',
    filterFn: 'contains',
  },
  {
    accessorKey: 'DETAIL_BAL_FL',
    header: 'Detail Bal Fl',
    filterFn: 'contains',
  },
  {
    accessorKey: 'STATUS',
    header: 'Status',
    filterFn: 'contains',
  },
  {
    accessorKey: 'REC_DATE',
    header: 'Date',
    filterFn: 'contains',
    Cell: ({ cell }) => dateFormat(cell.getValue())
  }
]

export const poSourceLineReactTableCols = [
  {
    accessorKey: 'ORIGIN_CD',
    header: 'Origin',
    filterFn: 'contains',
  },
  {
    accessorKey: 'DOCUMENT',
    header: 'Document',
    filterFn: 'contains',
  },
  {
    accessorKey: 'SRC_LINE_NBR',
    header: 'Line',
    filterFn: 'contains',
  },
  {
    accessorKey: 'REQUESTER',
    header: 'Requester',
    filterFn: 'contains',
  },
  {
    accessorKey: 'REQ_LOCATION',
    header: 'Location',
    filterFn: 'contains',
  },
  {
    accessorKey: 'QUANTITY',
    header: 'Qty',
    filterFn: 'contains',
  },
  {
    accessorKey: 'ENT_BUY_UOM',
    header: 'OUM',
    filterFn: 'contains',
  },
  {
    accessorKey: 'NAME',
    header: 'Name',
    filterFn: 'contains',
  },
  {
    accessorKey: 'EMAIL_ADDRESS',
    header: 'Email',
    filterFn: 'contains',
  },
  {
    accessorKey: 'COMPANY',
    header: 'Company',
    filterFn: 'contains',
  }
]