import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import NumberFormat from 'react-number-format'
import { dateFormat } from '../utils/formatter'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function PayRateHistory (props) {
  const classes = useStyles()

  const printValue = (lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(props.data[lookup]) : props.data[lookup]}${
            secondary && props.data[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? props.data[secondary]
                    : dateFormat(props.data[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Main Info</Typography>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={6} lg={6}>
                {printValue('FIRST_NAME', 'First Name')}
                {printValue('LAST_NAME', 'Last Name')}
                {printValue('MIDDLE_INIT', 'Middle Inital')}
                {printValue('NICK_NAME', 'Preferred Name')}
                {printValue('COMPANY', 'Company')}
                {printValue('FICA_NBR', 'Fica Number')}

                {printValue('EMP_STATUS', 'Status', 'EMP_STATUS_DESC')}
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                {printValue('DATE_HIRED', 'Hire Date')}
                {printValue('ADJ_HIRE_DATE', 'Adjusted Hire Date')}
                {printValue('SENIOR_DATE', 'Senior Date')}
                {printValue('TERM_DATE', 'Term Date')}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Assignment</Typography>
            {printValue('PROCESS_LEVEL', 'Process Level', 'PROCESS_LEVEL_NAME')}
            {printValue('DEPARTMENT', 'Department', 'DEPARTMENT_NAME')}
            {printValue('LOCAT_CODE', 'Location', 'LOCAT_DESC')}
            {printValue('POSITION', 'Position', 'POS_DESC')}
            {printValue('JOB_CODE', 'Job Code', 'JOB_CODE_DESC')}
            {printValue('SUPERVISOR', 'Supervisor', 'SUPERVISOR_DESC')}
            {printValue('BARGAIN_UNIT', 'Bargain Unit', 'BU_DESC')}
            {printValue('UNION_CODE', 'Union Code', 'UNION_DESC')}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Group</Typography>

            {printValue('PRIMARY_INDUSTRY_FOCUS', 'Primary Industry Focus')}
            {printValue('CAPABILITY_1', 'Capability 1')}
            {printValue('TECHNICAL_SPECILIAZATION', 'Technical Specialization')}
            {printValue('MD_ROLE_PRIORITY', 'MD Role Priority')}
            {printValue('CAPABILITY_2', 'Capability 2')}
            {printValue('INDUSTRY_2', 'Industry 2')}
            {printValue('POSITION_IN_ROLE', 'Position In Role')}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Employee Personal</Typography>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {printValue('BIRTHDATE', 'Birth Date')}
                {printValue('BIRTH_CITY', 'Birth City')}
                {printValue('BIRTH_STATE', 'Birth State')}
                {printValue('BIRTH_CNTRY_CD', 'Birth Country Code')}
                {printValue('FORMER_FST_NM', 'Former First Name')}
                {printValue('FORMER_LST_NM', 'Former Last Name')}
                {printValue('MAIDEN_LST_NM', 'Maiden Last Name')}
                {printValue('MAIDEN_FST_NM', 'Maiden First Name')}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                {printValue('SEX', 'Sex')}
                {printValue('VETERAN', 'Veteran')}
                {printValue('DECEASED', 'Deceased', 'DEATH_DATE', 'date')}
                {printValue('EEO_CLASS', 'Ethnicity', 'EEO_DESC')}
                {printValue('DISABILITY', 'Disability', 'DISABILITY_TYPE')}
                {printValue('TRUE_MAR_STAT', 'Marital Status')}
                {printValue('RELIGION', 'Religion')}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Grid
            container
            spacing={1}
            direction='row'
            justify='space-evenly'
            alignItems='stretch'
          >
            <Grid item xs={12} sm={6} md={12}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant='h5'>Address</Typography>
                <div className={classes.descriptVal}>{props.data['ADDR1']}</div>
                <div className={classes.descriptVal}>{props.data['ADDR2']}</div>
                <div className={classes.descriptVal}>{props.data['ADDR3']}</div>
                <div className={classes.descriptVal}>{props.data['ADDR4']}</div>
                <div
                  className={classes.descriptVal}
                >{`${props.data['CITY']}, ${props.data['STATE']} ${props.data['ZIP']}`}</div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Paper
                style={{ height: '100%', flexGrow: 1 }}
                elevation={3}
                className={classes.paper}
              >
                <Typography variant='h5'>Contact</Typography>
                {printValue('EMAIL_ADDRESS', 'Email')}
                {printValue('HM_PHONE_CNTRY', 'Home Phone Country')}
                {printValue('HM_PHONE_NBR', 'Home Phone Number')}
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={6}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Pay</Typography>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              <Grid item md={12} lg={6}>
                {printValue('SALARY_CLASS', 'Salary Class')}
                {printValue('PAY_FREQUENCY', 'Pay Frequency')}
                <div>
                  <span className={classes.descript}>{`Pay Rate:  `}</span>
                  <span className={classes.descriptVal}>
                    <NumberFormat
                      value={props.data['PAY_RATE']}
                      displayType='text'
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
                {printValue('ANNUAL_HOURS', 'Annual Hours')}
                {printValue('EXEMPT_EMP', 'Exempt from Overtime')}
              </Grid>
              <Grid item md={12} lg={6}>
                {printValue('PAY_STEP', 'Pay Step')}
                {printValue('PAY_GRADE', 'Pay Grade')}
                {printValue('SCHEDULE', 'Schedule')}
                {printValue('OT_PLAN_CODE', 'OT Plan Code')}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Work Info</Typography>
            {printValue('HIRE_SOURCE', 'Hire Source')}
            {printValue('I9_STATUS', 'I9 Status')}
            {printValue('WORK_STATE', 'Work State')}
            {printValue('TAX_STATE', 'Tax State')}
            {printValue('SEC_LVL', 'Security Level')}
            {printValue('SEC_LOCATION', 'Security Location')}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant='h5'>Work Eligibility</Typography>
            <Grid
              container
              spacing={1}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={12} md={4}>
                {printValue('I9_ALIEN_NBR', 'I9 Alien Number')}
                {printValue('I9_ADMIT_NBR', 'I9 Admit Number')}
                {printValue('I9_STA_EXP_DT', 'I9 Status Expiration Date')}
                {printValue('I9_AUTHORIZE', 'I9 Authorize')}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                Document 1{printValue('I9_DOC_TYPE_01', 'I9 Doc Type')}
                {printValue('I9_DOC_DESCR_01', 'Description')}
                {printValue('I9_DOC_NBR_01', 'Number')}
                {printValue('I9_DOC_EXP_DT_01', 'Expiration Date')}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                Document 2{printValue('I9_DOC_TYPE_02', 'I9 Doc Type')}
                {printValue('I9_DOC_DESCR_02', 'Description')}
                {printValue('I9_DOC_NBR_02', 'Number')}
                {printValue('I9_DOC_EXP_DT_02', 'Expiration Date')}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
