import React from 'react'
import {
  FormGroup,
  TextField,
  InputAdornment
} from '@material-ui/core'
import moment from 'moment'
import { dateFormat } from './formatter'

export const dateOptions = props => {
  return {
    filter: true,
    filterType: 'custom',
    customFilterListOptions: {
      render: v => {
        if (v[0] && v[1]) {
          return [
            `From ${props.displayName}: ${dateFormat(v[0])}`,
            `To ${props.displayName}: ${dateFormat(v[1])}`
          ]
        } else if (v[0]) {
          return `From ${props.displayName}: ${dateFormat(v[0])}`
        } else if (v[1]) {
          return `To ${props.displayName}: ${dateFormat(v[1])}`
        }
        return false
      },
      update: (filterList, filterPos, index) => {
        if (filterPos === 0) {
          filterList[index].splice(filterPos, 1, '')
        } else if (filterPos === 1) {
          filterList[index].splice(filterPos, 1)
        } else if (filterPos === -1) {
          filterList[index] = []
        }

        return filterList
      }
    },
    filterOptions: {
      fullWidth: true,
      names: [],
      logic (date, filters) {
        if (filters[0] && filters[1]) {
          return (
            moment(date) < moment(filters[0]) ||
            moment(date) > moment(filters[1])
          )
        } else if (filters[0]) {
          return moment(date) < moment(filters[0])
        } else if (filters[1]) {
          return moment(date) > moment(filters[1])
        }
        return false
      },
      display: (filterList, onChange, index, column) => (
        <div>
          {/* <FormLabel>{props.displayName}</FormLabel> */}
          <FormGroup row>
            <TextField
              fullWidth
              label={`${props.displayName} From`}
              type='date'
              value={filterList[index][0] || ''}
              onChange={event => {
                filterList[index][0] = event.target.value
                onChange(filterList[index], index, column)
              }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <div></div>
                    </InputAdornment>
                  </>
                )
              }}
              style={{ width: '48%', marginRight: '2%' }}
            />
            <TextField
              fullWidth
              label={`${props.displayName} To`}
              type='date'
              value={filterList[index][1] || ''}
              onChange={event => {
                filterList[index][1] = event.target.value
                onChange(filterList[index], index, column)
              }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <div></div>
                    </InputAdornment>
                  </>
                )
              }}
              style={{ width: '48%' }}
            />
          </FormGroup>
        </div>
      )
    }
  }
}
