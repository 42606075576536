import React from 'react';
import TableMui from './TableMui';
import moment from 'moment-timezone';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import getTableOptions from '../constants/TableOptions';
import { dedCols } from '../constants/EmployeeCols';
import {dateFormat} from '../utils/formatter';
import ReactTable from '../components/ReactTable'

export default function Deductions(props) {
  if (!props.data) return null;

  const options = getTableOptions({cols: dedCols, fileName: props.fileName});

  const tableData = props.data.map((row) => {
    return [
      row.Ded,
      row.Amount,
      row.Type,
      row.Balance,
      row.one,
      row.two,
      row.three,
      row.four,
      row.five,
      row.six,
      row.seven,
      row.eight,
      row.nine,
      dateFormat(new Date(row.Begin_Date)),
      dateFormat(new Date(row.End_Date)),
      row.Pty,
      row.Ar,
    ];
  });
console.log(props.data)
  return (
    <ReactTable cols={dedCols} data={props.data} />
      // <TableMui cols={dedCols} data={tableData} options={options} title="" />
  );
}
