import React from 'react';
import CustomizedTabs from '../components/Tabs';
import { IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { smallScreenWidth } from '../constants/AppConstants';

export default function EmployeeForm(props) {
  const { hasRole } = props.fetchInitialData;
  return (
    <div style={{padding: '1.5rem 0.5rem'}}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size="small"
        onClick={() => props.history.push('/')}
        aria-label="delete"
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth  && 'Dashboard'}
      </IconButton>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Employee
      </Typography>

      <div style={{margin: '1rem'}}>
        <CustomizedTabs {...props} />
      </div>
    </div>
  );
}
