export const makeSingleFilterDialog = (cols) => {
    return cols.map(col => {
        const colCopy = Object.assign({},col)
        if(colCopy.options.filterOptions) {
            colCopy.options.filterOptions = {
                fullWidth: true,
                ...colCopy.options.filterOptions
            }
        } else {
            colCopy.options['filterOptions'] = {
                fullWidth: true
            }
        }
        return colCopy
    })
}