import {
    currencyFormat,
    dateFormat
  } from '../utils/formatter'

  export const cashBookTransCols = [
    {
      name: 'CASH_CODE',
      label: 'Cash Code',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'BANK_INST_CODE',
      label: 'Payment',
      options: {
        filter: true,
        sort: true
      }
    },
    {
        name: 'COMPANY',
        label: 'Company',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'TRANS_NBR',
        label: 'Transaction',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'ISSUE_DATE',
        label: 'Issue Date',
        options: {
            customBodyRender: dateFormat,
          filter: true,
          sort: true
        }
      },
      {
        name: 'DESCRIPTION',
        label: 'Description',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'ISSUE_BNK_AMT',
        label: 'Amount',
        options: {
            customBodyRender: currencyFormat,
          filter: true,
          sort: true
        }
      },
      {
        name: 'BNK_TRANS_SIGN',
        label: 'Sign',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'REC_STATUS',
        label: 'Status',
        options: {
          filter: true,
          sort: true
        }
      }
  ]

  export const cashBookTransReactTableCols = [
    {
      accessorKey: 'CASH_CODE',
      header: 'Cash Code',
      filterFn: 'contains' 
    },
    {
      accessorKey: 'BANK_INST_CODE',
      header: 'Payment',
      filterFn: 'contains' 
    },
    {
        accessorKey: 'COMPANY',
        header: 'Company',
        filterFn: 'contains' 
      },
      {
        accessorKey: 'TRANS_NBR',
        header: 'Transaction',
        filterFn: 'contains' 
      },
      {
        accessorKey: 'ISSUE_DATE',
        header: 'Issue Date',
        filterFn: 'contains',
        Cell: ({ cell }) => dateFormat(cell.getValue())
      },
      {
        accessorKey: 'DESCRIPTION',
        header: 'Description',
        filterFn: 'contains' 
      },
      {
        accessorKey: 'ISSUE_BNK_AMT',
        header: 'Amount',
        filterFn: 'contains',
        Cell: ({ cell }) => currencyFormat(cell.getValue()) 
      },
      {
        accessorKey: 'BNK_TRANS_SIGN',
        header: 'Sign',
        filterFn: 'contains' 
      },
      {
        accessorKey: 'REC_STATUS',
        header: 'Status',
        filterFn: 'contains' 
      }
  ]