import React, { useState, useEffect } from 'react'
import LambdaFetch from '../functions/FetchFromLambda'
import MUIDataTable from 'mui-datatables'
import { debounce } from 'throttle-debounce'
import {Skeleton} from '@material-ui/lab'

import { TextField, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { testCols } from '../constants/GlCols'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CustomFooter from '../utils/CustomDataTableFooter'
import { createTheme } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'

const getMuiTheme = () =>
createTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: '3px 6px'
        }
      }
    }
  })

export function ServerSideTable (props) {
  const [state, setstate] = useState({

    isLoading: false,
    page: 0,
    count: -1,
    rowsPerPage: parseInt(props.fetchInitialData.credentials.serverSideRowCount),
    data: Array(25).fill(
      props.tableColumns.reduce((acc, el) => {
        acc[el.name] = <Skeleton height={20} />
        return acc
      }, {})),
    recentTableState: null
  })

  const [tableOptions, setTableOptions] = useState({
    responsive: 'standard'
  })
  const [data, setData] = useState(Array(25).fill(
    props.tableColumns.reduce((acc, el) => {
      acc[el.name] = <Skeleton height={20} />
      return acc
    }, {})))
  const [recentTableState, setRecentTableState] = useState(null)
  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     fetchData(state.page, null, state.rowsPerPage, null, {});
  //   }
  //   fetchMyAPI(props.fetchInitialData.credentials);
  // }, [state.rowsPerPage]);

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData(0, null, state.rowsPerPage, null, {})
    }
    fetchMyAPI(props.fetchInitialData.credentials)
  }, [props.params.chartName])

  const fetchData = async (
    curPage,
    sortOrder,
    numEntries,
    searchVal,
    filters
  ) => {
    const skel = Array(Math.min(numEntries, state.data.length)).fill(
      props.tableColumns.reduce((acc, el) => {
        acc[el.name] = <Skeleton height={20} />
        return acc
      }, {})
    )

    const sort = sortOrder ? `${sortOrder.name} ${sortOrder.direction}` : -1
    setstate({ ...state, isLoading: true, data: skel })
   

    try {
    const resp = await LambdaFetch(
      props.apiResource,
      'post',
      props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({
        action: props.action,
        params: props.params,
        columns: props.tableColumns,
        page: curPage,
        sortOrder: sort,
        entriesPerPage: numEntries,
        searchVal,
        filters
      }),
      '',
      props.fetchInitialData.credentials
    )
    console.log(resp)
let tableData = props.transform ? props.transform(resp.data.tableData) : resp.data.tableData

    if(props.callback) {
      props.callback()
    } 
    setstate({
      ...state,
      count: props.noPagination ? -1 : resp.data.count[0].COUNT,
      page: curPage,
      isLoading: false,
      data: tableData,
      recentTableState: {...searchVal, ...filters}
    })

  } catch(e) {
    props.callback(null,e)
  }
  }

  const DownloadData = async () => {
    try {
       LambdaFetch(
        props.apiResource,
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: props.downloadAction,
          params: props.params,
          searchVal: state.recentTableState ?  state.recentTableState.searchVal : -1,
          docLink: uuidv4()
        }),
        '',
        props.fetchInitialData.credentials,
        true
      )
      props.fetchInitialData.createSnack('File sent to print manager','success',3000)
     
  } catch(e) {
    console.log(e)
  }
}

  const handleTableChange = (action, tableState) => {
    const filters = tableState.filterList.reduce((acc, cur, index) => {
      acc[tableState.columns[index]['name']] = cur[0] ? cur[0] : null
      return acc
    }, {})

    switch (action) {
      case 'changePage':
        fetchData(
          tableState.page,
          tableState.sortOrder,
          state.rowsPerPage,
          tableState.searchText,
          filters
        )
        break
      case 'sort':
        fetchData(
          tableState.page,
          tableState.sortOrder,
          state.rowsPerPage,
          tableState.searchText,
          filters
        )
        break
      case 'search':
        debounceFetchTableData(tableState, state.rowsPerPage, filters)
        break
      case 'filterChange':
        debounceFetchTableData(tableState, state.rowsPerPage, filters)
      case 'resetFilters': 
      const isNullish = Object.values(state.recentTableState).every(value => {
        if (value === null) {
          return true;
        }
        return false;
      });
      if(!isNullish){
        debounceFetchTableData(tableState, state.rowsPerPage, filters) 
      }  
        break
    }
  }


  const debounceFetchTableData = debounce(
    1000,
    false,
    (tableState, numRows, filters) => {
      const { page, sortOrder, searchText } = tableState
      fetchData(page, sortOrder, numRows, searchText, filters)
    }
  )
  const { page, count, rowsPerPage } = state

  const options = {
    setRowProps: (row, dataIndex, rowIndex) => {
      if (rowIndex % 2 === 0) {
        return {
          style: { background: '#F7F7F7' }
        }
      }
    },
    responsive: 'standard',
    tableBodyMaxHeight: '620px',
    selectableRows: 'none',
    filterType: 'textField',
    filter: true,
    rowHover: false,
    print: false,
    elevation: props.elevation ? props.elevation : 0,
    displaySelectToolbar: false,
    rowsPerPage: state.rowsPerPage,
    rowsPerPageOptions: [state.rowsPerPage],
    serverSide: true,
    sort: props.noSort ? false : true.valueOf,
    count: count,
    page,
    onTableChange: handleTableChange,
    onDownload: (buildHead, buildBody, columns, data) => {
      if(props.downloadAction && state.data.length > rowsPerPage - 1) {
        DownloadData()
        return false
      }
      return "\uFEFF" + buildHead(columns) + buildBody(state.data); 
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          {...props}
          count={state.count}
          page={state.page}
          rowsPerPage={state.rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          tableData={state.data}
        />
      )
    }
  }
  return (
    <div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={props.tableTitle}
          data={state.data}
          serverSide={true}
          columns={props.tableColumns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  )
}
